<template>
  <div class="row">
<!--    <div class="col-lg-12">-->

<!--              <div class="row pl-1">-->
<!--          <div class="filters__multiple">-->
<!--            <div class="input-group input-group-sm filters__input">-->
<!--                  <span class="input-group-addon filters__field-icon"><i-->
<!--                    class="fa fa-map-marker"></i></span>-->
<!--              <select @change="refreshLicenseDailyLog"-->
<!--                      class="form-control form-control-sm filters__field"-->
<!--                      v-model="filters.location">-->
<!--                <option :selected="true" :value="'All locations'">{{-->
<!--                  $t('general.allLocations')-->
<!--                  }}-->
<!--                </option>-->
<!--                <option v-for="location in locationsList" :value="location.id"-->
<!--                        :key="location.id">-->
<!--                  {{ location.name }}-->
<!--                </option>-->
<!--              </select>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div-->
<!--            class=" d-flex  align-items-center switcher-with-childs-for-use-in-filters-wrapper">-->
<!--            <div-->
<!--              class="  d-flex align-items-end justify-content-start"-->
<!--            >-->
<!--              <Switch-component-->
<!--                :disabled="this.filters.location === 'All locations'"-->
<!--                v-model="filters.with_childs"-->
<!--                class="switcher-with-childs-for-use-in-filters"-->
<!--                @change="refreshLicenseDailyLog"-->
<!--              />-->
<!--            </div>-->
<!--            <div-->
<!--              class="input-group input-group-sm filters__location switcher-with-childs-for-use-in-filters-caption ">-->
<!--              <small class="with-child">{{ $t('general.withChild') }}</small>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->


<!--      <button class="btn btn-warning btn-md ml-auto float-right" @click="closeLicensesStats">-->
<!--        {{ $t('licenses.licensesStatsClose') }}-->
<!--      </button>-->
<!--    </div>-->


        <div class="col-lg-12">
          <div class="row">
            <div class="col-lg-12">
              <WButton warning
                customClass="ml-auto float-right"
                @click="closeLicensesStats"
              >
                {{ $t('licenses.licensesStatsClose') }}
              </WButton>
          </div>.
          </div>

          <div class="row mb-h" >
                            <div
                  class="col-lg-3 input-group-for-location-LicensesStatistics--filters " v-if="areLocationsVisible">
                                <span class="input-group-addon filters__field-icon">
                  <i class="fa fa-map-marker"
                     :class="{ 'text-primary': filters.location !== 'All locations' }"></i>
                </span>
                  <SelectComponentV3ForUseInLocationSelectors
                    :show-search-field-into-items-list="!isSelectedDefaultLocationForRequests || (isSelectedDefaultLocationForRequests && $store.getters.getDefaultWithChildsForRequests())"
                    v-if="showFilters"
                    no-wrap-selected-option
                    class="select-location-in-LicensesStatistics--filters"
                    enable-max-option-width
                    small-size
                     :no-clear-selected-option-when-click-on-it="isSelectedDefaultLocationForRequests"
                   :show-delete-button="!isSelectedDefaultLocationForRequests"
                    v-model="locationInFiltersData"
                    track-by="name"
                    option-id-name="id"
                    :no-options-found="$t('general.noResultForSearch')"
                    :async-function="requestLocations.bind(this)"
                    :placeholder="$t('portalStats.selectLocation')"
                    @select="selectLocationInFilters"
                  >
                    <template #dropdown-start>
                      <div class="ml-h mt-1">
                        <Switch-component
                          v-if="!isSelectedDefaultLocationForRequests || (isSelectedDefaultLocationForRequests && $store.getters.getDefaultWithChildsForRequests())"
                          @input="refreshLicenseDailyLog"
                          :label="$t('general.withChild')"
                          v-model="filters.with_childs"
                          class=""
                        />
                      </div>
                    </template>
                  </SelectComponentV3ForUseInLocationSelectors>
                            </div>
          </div>
          <div class="row ">
              <div class="col-lg-4">
        <div class="filters__multiple">
          <div class="input-group input-group-sm filters__wlan flex-wrap mr-1">
            <WButton secondary outline sm v-if="isFiltersActive"
              customClass="new-modern-style-btn-in-filters mt-0"
              @click.stop.prevent="resetAllFilters"
            >
              <i class="fa fa-close"></i>
              {{ $t('general.resetAllFilters') }}
            </WButton>
          </div>
        </div>
      </div>
      </div>



    </div>

    <div class="col-lg-12" v-if="isSummaryLoading">
      <div class="loader loader-backdrop loader-marketing" v-if="isSummaryLoading"></div>
    </div>
    <div v-else class="col-lg-12">
      <div class="card">
        <div class="card-header">
          <i class="glyphicons glyphicons-pie-chart glyphicons--shrink mr-0"></i>
          {{ $t('portalStats.stats') }}
          <div class="float-right d-flex align-items-start" style="min-width: 300px">

               <div class="mr-1" style="min-width: 300px;">
                <div class="filter">
<!--                  <label>{{ $t('portalStats.selectRange') }}</label>-->
                  <div class="input-group input-group filters__location">
                    <date-picker
                      v-if="showFilters"
                      class="datepicker_width_full datepicker_height_40"
                      v-model="drPickerDate"
                      type="date"
                      :onChange="handleDateChangeForStat.bind(this)"
                    />
                  </div>
                </div>
              </div>

              <div class="w-100 d-flex flex-column justify-content-end download-button-in-LicensesStatistics">
                <!-- <h4 class="m-0"> -->
                <download-extract-v2
                  newXlsAutoColumnsWidth
                  :manual-disable="disableDownloadButton"
                  v-if="drPickerDate"
                  class="mt-auto float-right"
                  :data="formatAllStats()"
                  :data-for-excel="formatAllStatsToExcel()"
                  excel-file-type="xlsx"
                  :excel-file-name="getFileNameForDownloadFiles($t('downloadFilesNames.licenses'))"
                  :filename="getFileNameForDownloadFiles($t('downloadFilesNames.licenses'))"
                  :downloads-formats-for-show="{
                    oldCsv: true,
                    newCsv: false,
                    oldXls: false,
                    newXls: true,
                    pdf: true
                  }"
                />
                <!-- </h4> -->
              </div>

          </div>
        </div>
        <div class="card-block">
          <section>
<!--            <div class="row">-->
<!--              <div class="col-lg-5 col-sm-5">-->
<!--                <div class="filter">-->
<!--                  <label>{{ $t('portalStats.selectRange') }}</label>-->
<!--                  <div class="input-group input-group filters__location">-->
<!--                    <date-picker-->
<!--                      class="datepicker_width_full datepicker_height_40"-->
<!--                      v-model="drPickerDate"-->
<!--                      type="date"-->
<!--                      :onChange="handleDateChangeForStat.bind(this)"-->
<!--                    />-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->

<!--              <div class="col-lg-2 col-sm-2 submit-width_full">-->
<!--                <button-->
<!--                  class="btn"-->
<!--                  :class="{ 'btn-success': !submitButton, 'btn-default': submitButton }"-->
<!--                  :disabled="submitButton"-->
<!--                  type="button"-->
<!--                  @click="getLicenseDailyLog(adId)"-->
<!--                >-->
<!--                  {{ $t('marketing.submit') }}-->
<!--                </button>-->
<!--              </div>-->
<!--              <div class="col-lg-2 col-sm-2 d-flex flex-column justify-content-end submit-width_full">-->
<!--                &lt;!&ndash; <h4 class="m-0"> &ndash;&gt;-->
<!--                <download-extract-v2-->
<!--                  newXlsAutoColumnsWidth-->
<!--                  :manual-disable="disableDownloadButton"-->
<!--                  v-if="drPickerDate"-->
<!--                  class="mt-auto float-right"-->
<!--                  :data="formatAllStats()"-->
<!--                  :data-for-excel="formatAllStatsToExcel()"-->
<!--                  excel-file-type="xlsx"-->
<!--                  :excel-file-name="getFileNameForDownloadFiles($t('downloadFilesNames.licenses'))"-->
<!--                  :filename="getFileNameForDownloadFiles($t('downloadFilesNames.licenses'))"-->
<!--                  :downloads-formats-for-show="{-->
<!--                    oldCsv: true,-->
<!--                    newCsv: false,-->
<!--                    oldXls: false,-->
<!--                    newXls: true,-->
<!--                    pdf: true-->
<!--                  }"-->
<!--                />-->
<!--                &lt;!&ndash; </h4> &ndash;&gt;-->
<!--              </div>-->
<!--            </div>-->
          </section>
          <div v-if="isNoData === false">
            <section class="graph-block">
              <div v-if="averageValues != undefined" class="row mb-3 mt-1">
                <div class="col-lg-12 col-md-12">
                  <div class="d-flex flex-column align-items-center justify-content-center">
                    <div class="text-center">
                      <h4 class="mb-0">{{ $t('licenses.avgValuesCaption') }}</h4>
                      <p class="mt-0">
                        {{ $t('licenses.forTheSelectedPeriodCaption') }} ({{
                          returnDateForShow(drPickerDate.startDate)
                        }}
                        - {{ returnDateForShow(drPickerDate.endDate) }})
                      </p>
                    </div>
                    <div
                      v-if="showAvgCounterBlock"
                      class="d-flex justify-content-around counters-container-in-licensestat"
                    >
                      <div class="d-flex flex-column justify-content-center align-items-center text-center">
                        <div>
                          <h4>{{ $t('licenses.types.cpes') }}</h4>
                        </div>
                        <div>
                          <h4 v-if="cpeAvg !== undefined">
                            {{ cpeAvg }}
                          </h4>
                          <h6 v-else>
                            {{ $t('general.noDataToDisplay') }}
                          </h6>
                        </div>
                      </div>
                      <div class="d-flex flex-column justify-content-center align-items-center text-center">
                        <div>
                          <h4>{{ $t('licenses.types.radar') }}</h4>
                        </div>
                        <div>
                          <h4 v-if="radarAvg !== undefined">
                            {{ radarAvg }}
                          </h4>
                          <h6 v-else>
                            {{ $t('general.noDataToDisplay') }}
                          </h6>
                        </div>
                      </div>
                      <div class="d-flex flex-column justify-content-center align-items-center text-center">
                        <div>
                          <h4>{{ $t('licenses.types.heatmap') }}</h4>
                        </div>
                        <div>
                          <h4 v-if="heatmapAvg !== undefined">
                            {{ heatmapAvg }}
                          </h4>
                          <h6 v-else>
                            {{ $t('general.noDataToDisplay') }}
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      {{ $t('general.noDataToDisplay') }}
                    </div>
                  </div>
                </div>
              </div>
              <h4 class="text-center mt-2 mb-3">
                {{ $t('licenses.licensesAllConnected') }}
                <!--                <download-extract-v2-->
                <!--                  v-if="true"-->
                <!--                  class="mt-auto float-right"-->
                <!--                  :data="formatBarToCSV(connectedChart, $t('licenses.licensesAllConnected'))"-->
                <!--                  :data-for-excel="formatAllStatsToExcel(connectedChart, $t('licenses.licenses'))"-->
                <!--                  excel-file-type="xlsx"-->
                <!--                  :excel-file-name="`stat-licenses-${moment(new Date()).format('lll')}`"-->
                <!--                  :filename="`stat-licenses-${moment(new Date()).format('lll')}`"-->
                <!--                  :downloads-formats-for-show="{-->
                <!--                    oldCsv: false,-->
                <!--                    newCsv: true,-->
                <!--                    oldXls: false,-->
                <!--                    newXls: true,-->
                <!--                    pdf: true,-->
                <!--                  }"-->
                <!--                />-->
              </h4>
              <div class="row">
                <div class="col-lg-12 col-md-12">
                  <highcharts :options="connectedChart" id="connectedChart"></highcharts>
                  <SavePngButton
                    class="save-icon color-brand"
                    id="connectedChart"
                    :name="$t('licenses.licensesAllConnected')"
                  />
                </div>
              </div>
            </section>
          </div>
          <div v-else>
            {{ $t('general.noDataToDisplay') }}
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-12" v-if="isNoData === false && isSummaryLoading === false">
      <WButton warning
        customClass="ml-auto float-right"
        @click="closeLicensesStats"
      >
        {{ $t('licenses.licensesStatsClose') }}
      </WButton>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import licensesStatsService from '../../services/licensesStatsService';
import DatePicker from '../date-picker.vue';
import SavePngButton from '../save-png-button.vue';
import helpers from '../../helpers';
import SwitchComponent from '../../components/Universal/Switch-component.vue';
import locationService from "../../services/locationService";
import SelectComponentV2 from '../../components/Universal/select/select-componentV2.vue';
import SelectComponentV3ForUseInLocationSelectors from '../../components/Universal/select/select-componentV3ForUseInLocationSelectors.vue';


export default {
  name: 'LicenseStats',
  components: {
    // Multiselect,
    SavePngButton,
    DatePicker,
    SwitchComponent,
    SelectComponentV2,
    SelectComponentV3ForUseInLocationSelectors
  },
  props: {
    adId: String,
    adName: String,
    dateStart: Number
  },
  data() {
    return {
      showFilters: true,
      showLocationFilter: true,
      locationInFiltersData: [],
      filters: {
        location: 'All locations',
        with_childs: true,
      },
      averageValues: undefined,
      isNoData: false,
      submitButton: true,
      drPickerDate: {
        startDate: moment().startOf('week').valueOf(),
        endDate: moment().endOf('week').valueOf()
      },
      connectedChart: {
        time: {
          useUTC: false
        },
        chart: {
          type: 'column',
          zoomType: 'x',
          panning: true,
          panKey: 'shift'
        },
        title: {
          text: ''
        },
        subtitle: {
          text: this.$t('statistics.pan')
        },
        xAxis: {
          type: 'datetime',
          dateTimeLabelFormats: {
            day: '%d.%m.%Y',
            month: '%m.%y'
          }
        },
        yAxis: {
          // min: 0,
          allowDecimals: false,
          title: {
            text: ''
          }
        },
        tooltip: {
          headerFormat: '<span style="font-size:10px"><b>{point.key}</b></span><table>',
          pointFormat:
            '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b> {point.y}</b></td></tr>',
          footerFormat: '</table>',
          useHTML: true,
          xDateFormat: '%d.%m.%Y'
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0
          }
        },
        series: []
      }
    };
  },
  computed: {
    isSelectedDefaultLocationForRequests() {
      if (this.$store.getters.getDefaultLocationForRequests(this)) {
        return true;
      } else {
        return false;
      }
    },
    userBaseLocation() {
      return this.$store.state.userData.base_location;
    },
    locationsList() {
      return this.$store.getters.locationsListSorted;
    },
    isFiltersActive(){
      return this.filters.location !== 'All locations' ||
        this.drPickerDate.startDate !== moment().startOf('week').valueOf() || this.drPickerDate.endDate !== moment().endOf('week').valueOf()
    },
    disableDownloadButton() {
      if (!this.showAvgCounterBlock || this.isNoData) {
        return true;
      }
      return false;
    },
    showAvgCounterBlock() {
      if (this.cpeAvg === undefined && this.radarAvg === undefined && this.heatmapAvg === undefined) {
        // если все счетчики undefined то вобще не показываем блок с ними
        return false;
      }
      return true;
    },
    cpeAvg() {
      if (isNaN(this.averageValues.cpeAvg)) {
        return undefined;
      }
      return this.averageValues.cpeAvg;
    },
    radarAvg() {
      if (isNaN(this.averageValues.radarAvg)) {
        return undefined;
      }
      return this.averageValues.radarAvg;
    },
    heatmapAvg() {
      if (isNaN(this.averageValues.heatmapAvg)) {
        return undefined;
      }
      return this.averageValues.heatmapAvg;
    },
    isSummaryLoading() {
      return this.$store.state.loadingSummary;
    }
  },
  methods: {
    refreshLicenseDailyLog() {
      licensesStatsService.getLicenseDailyLog(this, true);
    },
    redrawFilters(){
      this.showFilters = false;
      this.$nextTick(()=>{
        this.showFilters = true;
      })
    },
    // resetAllFilters() {
    //   this.filters = {
    //     location: 'All locations',
    //     with_childs: true,
    //   }
    //   this.locationInFiltersData = [];
    //   this.drPickerDate = {
    //     startDate: moment().startOf('week').valueOf(),
    //     endDate: moment().endOf('week').valueOf()
    //   },
    //     this.redrawFilters()
    //   this.refreshLicenseDailyLog()
    // },
    resetAllFilters() {
      this.filters = {
        // location: 'All locations',
        // with_childs: true,
      }
      this.locationInFiltersData = [];
      this.drPickerDate = {
        startDate: moment().startOf('week').valueOf(),
        endDate: moment().endOf('week').valueOf()
      }
      if (!this.isSelectedDefaultLocationForRequests) {
        this.filters.location = 'All locations';
        this.filters.with_childs = true;
        this.locationInFiltersData = [];
      } else {
        this.filters.location = this.$store.getters.getDefaultLocationForRequests(this)
        this.filters.with_childs = this.$store.getters.getDefaultWithChildsForRequests(this);
        this.locationInFiltersData = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject;
      }

      this.redrawFilters()
      this.refreshLicenseDailyLog()
    },
    getFileNameForDownloadFiles(pageName) {
      return helpers.getFileNameForDownloadFiles(pageName)
    },
    getAvgValueForDownloadFile(avgName) {
      // console.log(avgName);
      if (avgName === this.$t('licenses.types.cpes')) {
        if (this.cpeAvg !== undefined) {
          return this.cpeAvg;
        }
      }
      if (avgName === this.$t('licenses.types.radar')) {
        if (this.radarAvg !== undefined) {
          return this.radarAvg;
        }
      }
      if (avgName === this.$t('licenses.types.heatmap')) {
        if (this.heatmapAvg !== undefined) {
          return this.heatmapAvg;
        }
      }
      return this.$t('general.noDataToDisplay');
    },
    returnDateForShow(jsTS) {
      return moment(jsTS).format('D MMM YYYY, H:mm');
    },
        selectLocationInFilters(location) {
      // console.log(location)
      if (!location) {
        this.filters.location = 'All locations'
      } else {
        this.filters.location = location.id;
        // this.addLocationInPreviouslySelectedLocationsInFilter(location)
      }
      this.refreshLicenseDailyLog();

    },
    async requestLocations(searchQuery) {
      // return locationService.requestLocations(searchQuery);
      // return locationService.requestLocationsWithSort(searchQuery);
      return locationService.requestLocationsWithSortAndFilteringByChildsForLocationSelectInPages(this, searchQuery)
    },
    closeLicensesStats() {
      this.$emit('closeLicensesStats');
    },
    getLicenseDailyLog() {
      this.submitButton = true;
      this.isNoData = false;
      // licensesStatsService.getLicenseDailyLog(this);
      licensesStatsService.getLicenseDailyLog(this, true);
    },
    changeProfile() {
      this.submitButton = false;
    },
    handleDateChangeForStat() {
      this.submitButton = false;
      this.refreshLicenseDailyLog();
    },
    // formatBarToCSV(graphData, graphName = '') {   //тут загловок по центру
    //   if (graphData.series.length === 0) {
    //     return [];
    //   }
    //   const firstRow = {};
    //   const result = graphData.series.map((el) => {
    //     const row = {};
    //     graphData.xAxis.categories.forEach((date, index, array) => {
    //       row[this.$t('licenses.licenses')] = this.$t(`licenses.types.${el.name}`);
    //       row[date] = el.data[index];
    //       // Делаем строки с названием каждой статистики
    //       if (Math.round(array.length / 2) - 1 === index) {
    //         firstRow[this.$t('licenses.licenses')] = '';
    //         firstRow[date] = graphName;
    //       } else {
    //         firstRow[this.$t('licenses.licenses')] = '';
    //         firstRow[date] = '';
    //       }
    //     });
    //     return row;
    //   });
    //   return [firstRow, ...result];
    // },
    // тут загловок по влево
    formatBarToCSV(graphData, graphName = '') {
      if (graphData.series.length === 0) {
        return [];
      }
      const firstRow = {};
      firstRow[this.$t('licenses.licenses')] = graphName;
      const result = graphData.series.map((el) => {
        const row = {};
        graphData.xAxis.categories.forEach((date, index, array) => {
          // row[this.$t('licenses.licenses')] = this.$t(`licenses.types.${el.name}`);
          row[this.$t('licenses.licenses')] = el.name;
          row[this.$t('licenses.avgValueCaption')] = this.getAvgValueForDownloadFile(el.name);
          row[date] = el.data[index];
          // Делаем строки с названием каждой статистики
          // if (Math.round(array.length / 2) - 1 === index) {
          //   firstRow[this.$t('licenses.licenses')] = '';
          //   firstRow[date] = graphName;
          // } else {
          //   firstRow[this.$t('licenses.licenses')] = '';
          //   firstRow[date] = '';
          // }
          firstRow[date] = '';
        });
        // console.log(el.name)
        return row;
      });
      // return [firstRow, ...result];
      // console.log(...result)
      return [...result];
    },
    formatAllStats() {
      return [...this.formatBarToCSV(this.connectedChart, this.$t('licenses.licensesAllConnected'))];
    },
    formatBarToExcel(graphData, graphName = 'data') {
      if (graphData.series.length === 0) {
        return { [graphName]: [] };
      }
      const result = [];
      result.push([graphName, this.$t('licenses.avgValueCaption'), ...graphData.xAxis.categories]);
      // console.log(result)
      graphData.series.forEach((row) => result.push([row.name, this.getAvgValueForDownloadFile(row.name), ...row.data]));
      // console.log({ [graphName]: result })
      return { [graphName]: result };
    },
    formatAllStatsToExcel() {
      return { ...this.formatBarToExcel(this.connectedChart, this.$t('licenses.licenses')) };
    }
  },
  // created() {
  //   if (this.dateStart !== null) {
  //     this.drPickerDate.startDate = this.dateStart;
  //     this.drPickerDate.endDate = moment(this.dateStart).endOf('day').valueOf();
  //   }
  //   // NOTE: getting data from /api/admin/range
  //   this.getLicenseDailyLog(this);
  // }
  created() {
    if (this.dateStart !== null) {
      this.drPickerDate.startDate = this.dateStart;
      this.drPickerDate.endDate = moment(this.dateStart).endOf('day').valueOf();
    }

    let inHeaderLocationSelectedApplying = true;
    if (this.$store.state.inHeaderLocationSelectModeEnabled &&
      inHeaderLocationSelectedApplying) {
      let withChildsForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.withChilds;
      let locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationID;
      if (!locationForFiltering || locationForFiltering === '') {
        // locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.userBaseLocation
        locationForFiltering = 'All locations';
      }

      if (locationForFiltering === 'All locations') {
        this.locationInFiltersData = [];
      } else {
        this.locationInFiltersData = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject
      }


      this.filters.location = locationForFiltering;
      this.filters.with_childs = withChildsForFiltering;
    }

    // NOTE: getting data from /api/admin/range
    this.getLicenseDailyLog(this);
  }
};
</script>

<style lang="scss" scoped>
.counters-container-in-licensestat {
  width: 70%;
}
.graph-block {
  padding: 20px 0 0 0;
}
.datepicker_width_full {
  width: 100%;
}

.traffic-profit {
  flex-grow: 1;
  margin-top: 10px;
  margin-bottom: 90px;
}

.category-profit {
  flex-grow: 1;
  margin-top: 20px;
}

.text-active {
  color: rgba(255, 143, 7, 1);
}

.text-loyalty {
  color: rgba(253, 216, 53, 1);
}

.text-returned {
  color: rgba(255, 143, 7, 0.8);
}

.text-new {
  color: #81c784;
}

.value {
  font-size: 1.7rem;

  &--big {
    font-size: 2.4rem;
  }

  &--large {
    font-size: 2rem;
  }

  &--title {
    font-size: 0.9rem;
  }
}

.card-new {
  border-top: 4px solid #81c784;
}

.card-returned {
  border-top: 4px solid rgba(255, 143, 7, 0.8);
}

.card-unique {
  border-top: 4px solid rgb(124, 181, 236);
}

.text-returned {
  color: rgba(255, 143, 7, 0.8);
}

.card-default {
  border-top: 4px solid rgba(183, 183, 183, 0.5);
}

.card-summary {
  margin-top: -30px;
}

.text-profit {
  font-size: 1.5rem;
}

.rateHalf {
  height: 300px;
}

.filter-table {
  width: 140px;
}

.text-muted {
  color: #94a0b2 !important;
}

.text-muted-light {
  color: #bcc5d0 !important;
}

.submit-width_full {
  & button {
    // position: relative;
    margin-top: 28px;
    height: 40px;
    width: 100%;
  }
}

/*.loader-backdrop {*/
/*  &:after {*/
/*    position: absolute;*/
/*    width: 100px;*/
/*    height: 100px;*/
/*    top: 10%;*/
/*    border: 5px solid #e6e6e6;*/
/*    border-top-color: #1bb3a0;*/
/*  }*/
/*}*/

.vendor-table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;

  & tbody {
    display: block;
    width: 100%;
    overflow: auto;
    height: 400px;
  }

  & thead tr {
    display: block;
  }

  & th,
  & td {
    padding: 5px;
    text-align: left;
    width: 150px;
  }
  & .vendor-cell {
    width: 300px;
  }
}

.save-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
}
</style>

<style lang="scss">
    .download-button-in-LicensesStatistics button{
        height: 40px !important;
    width: 100%;
  }
      .input-group-for-location-LicensesStatistics--filters {
    // min-height: 27px !important;
    position: relative;
    display: flex;

    align-items: stretch;
    width: 100%;
    max-width: 100%;

  }
      .select-location-in-LicensesStatistics--filters .select__list-container {
    width: 400px;
  }


.loader-marketing:after {
  position: absolute;
  /*top: calc(50vh);*/
  top: 30px;
}
.submit-width_full {
  & button {
    // position: relative;
    margin-top: 28px;
    height: 40px;
    width: 100%;
  }
}
</style>
<!--background-image: url(https://leroy-moskva.ru/wp-content/uploads/2018/06/Lerua-Merlen-Lefortovo-shema.jpg);-->
<!--background-size: contain;-->
